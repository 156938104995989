import React from "react"

import Layout from "../components/layout"
import SeoMeta from "../components/seo"
import Carousel from "../components/homepage/carousel"
import { EccBullets } from "../components/ecc-bullets"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SeoMeta title="Preschool" />
      <Carousel />
      <div className="container">
        <div className="row">
          <div className="col-8 offset-2">
            <EccBullets />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
