import { useStaticQuery, graphql } from "gatsby"
import React, { useState } from "react"
import Carousel from 'react-bootstrap/Carousel'
import { Helmet } from 'react-helmet'
import { getSrc } from "gatsby-plugin-image"

const CarouselBlurbs = props => {
  const { index, data } = props
  return (
    <div id="carousel-blurbs" className="container pg-empty-placeholder">
      {data.edges.map((edge, key) => {
        const { node } = edge
        const visible = key === index ? '' : 'd-none'
        return (
          <div key={key} className={`py-3 ${visible}`}>
            <h2>{node.heading}</h2>
            <div dangerouslySetInnerHTML={{ __html: node.content }} />
          </div>
        )
      })}
    </div>
  )
}

const HomepageCarousel = () => {
  const data = useStaticQuery(graphql`
    query {
      carousel: allHomepageCarouselYaml {
        edges {
          node {
            caption
            heading
            content
            image {
              large: childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 2000, quality: 100)
              }
              medium: childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 992, quality: 100)
              }
              small: childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 768, quality: 100)
              }
            }
          }
        }
      }
    }
  `)
  const [index, setIndex] = useState(0)
  const [direction, setDirection] = useState(null)
  const handleSelect = (selectedIndex, direction, e) => {
    setIndex(selectedIndex)
    setDirection(direction)
  }
  return (
    <>
      <Helmet>
        <style type="text/css">{`
          /* .carousel-inner {
            display: flex;
            max-height: 360px;
          } */
          .carousel-caption {
            position: relative;
            height: 100%;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }
          .carousel-caption > h3:nth-child(1) {
            position: absolute;
            bottom: 5%;
            left: 8%;
            text-align: left;
            font-size: 3.5em;
          }
          .carousel-caption > h3 > span {
            // position: absolute;
            // bottom: 15%;
            // right: 15%;
            // text-align: right;
            font-size: 1.3em;
            font-family: Arizonia;
            font-display: swap;
          }
          .carousel-caption > h3:nth-child(2) {
            position: absolute;
            bottom: 15%;
            right: 15%;
            text-align: right;
            font-size: 4em;
            font-family: Arizonia;
            font-display: swap;
          }
          @media screen and (max-width: 680px) {
            .carousel-caption > h3:nth-child(2) {
              padding-top: 0vh;
            }
            /* .carousel-item:nth-child(1) > img {
              top: 0 !important;
            }
            .carousel-item:nth-child(2) > img {
              top: 0 !important;
            } */
          }
          .carousel-item {
            background-attachment: fixed;
            background-position: top;
            background-repeat: no-repeat;
            background-size: cover;
            height: 600px;
          }
          /* .carousel-item {
            min-height: 296px;
            max-height: 396px;
          }
          .carousel-item > img {
            position: absolute;
            min-height: 296px;
          }
          .carousel-item:nth-child(1) > img {
            top: -110px;
          }
          .carousel-item:nth-child(2) > img {
            top: -80px;
          }
          .carousel-item:nth-child(3) > img {
            top: -120px;
          }
          .carousel-item:nth-child(4) > img {
            top: -18vh;
          } */
        `}</style>
      </Helmet>
      <Carousel fade interval={null} activeIndex={index} direction={direction} onSelect={handleSelect}>
        {data.carousel.edges.map((edge, key) => {
          const { node } = edge
          const largeImage = getSrc(node.image.large)
          const mediumImage = getSrc(node.image.medium)
          const smallImage = getSrc(node.image.small)

          return (
            <Carousel.Item key={key}>
              <Helmet>
              <style type="text/css">{`
                .carousel-item:nth-child(${key+1}) {
                  background-image: linear-gradient(
                    rgba(0, 0, 0, 0.3),
                    rgba(0, 0, 0, 0.3)
                  ), url(${largeImage})
                }
                @media (max-width: 991.98px) {
                  .carousel-item:nth-child(${key+1}) {
                    background-image: linear-gradient(
                      rgba(0, 0, 0, 0.3),
                      rgba(0, 0, 0, 0.3)
                    ), url(${mediumImage})
                  }
                }
                @media (max-width: 767.98px) {
                  .carousel-item:nth-child(${key+1}) {
                    background-image: linear-gradient(
                      rgba(0, 0, 0, 0.3),
                      rgba(0, 0, 0, 0.3)
                    ), url(${smallImage})
                  }
                }
              `}</style>
              </Helmet>
              {/* <img className="d-block align-self-center img-fluid" src={node.image.childImageSharp.fluid.src} alt="Children playing" /> */}
              {/* <img
                className="d-block w-100"
                src={node.image.childImageSharp.fluid.src}
                alt="Children playing" /> */}
              <Carousel.Caption>
                <h3>{node.caption[0]} <span>{node.caption[1]}</span></h3>
                {/* <h3>{node.caption[1]}</h3> */}
              </Carousel.Caption>
            </Carousel.Item>
          )
        })}
      </Carousel>
      <CarouselBlurbs index={index} data={data.carousel} />
    </>
  )
}

export default HomepageCarousel
